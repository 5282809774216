import { Injectable } from '@angular/core'
import { BehaviorSubject, timer } from 'rxjs'
import { StorageService } from '@core/services/storage.service'
import { NotificationService } from '@core/data-services/shared/communication/notification.service'
import { AuthService } from '@core/services/api/auth.service'

@Injectable({ providedIn: 'root' })
export class SidebarService {

	public opened$: BehaviorSubject<boolean>
	public locked$: BehaviorSubject<boolean>
  public darkModeEnabled$: BehaviorSubject<boolean>
  public notificationAmount$: BehaviorSubject<number>

	constructor(
		private storageService: StorageService,
		private notificationService: NotificationService,
		private authService: AuthService,
	) {

		let opened: boolean = storageService.getBoolean('sidebar.opened')
		let locked: boolean = storageService.getBoolean('sidebar.locked')
    let darkModeEnabled: boolean = storageService.getBoolean('darkModeEnabled')

    if (locked) {
			opened = true
		}

		this.opened$ = new BehaviorSubject<boolean>(opened)
		this.locked$ = new BehaviorSubject<boolean>(locked)
    this.darkModeEnabled$ = new BehaviorSubject<boolean>(darkModeEnabled)
    this.notificationAmount$ = new BehaviorSubject<number>(0)

		this.pollForNotificationUpdates()
	}

	public setOpened(value: boolean): void {
		this.storageService.set('sidebar.opened', value)
		this.opened$.next(value)
	}

	public setLocked(value: boolean): void {
		this.storageService.set('sidebar.locked', value)
		this.locked$.next(value)
	}

  public toggleDarkMode(value: boolean): void {
    this.storageService.set('darkModeEnabled', value)
    this.darkModeEnabled$.next(value)
  }

	public setNotificationAmount(value: number): void {
		this.notificationAmount$.next(value)
	}

	private pollForNotificationUpdates(): void {
		const pollInterval = 60000 * 10 // every ten minutes

		timer(0, pollInterval).subscribe(() => {
			this.updateNotificationAmount()
		})
	}

	private updateNotificationAmount(): void {
		if (this.authService.isLoggedIn()) {
			// make request to notifications endpoint
			this.notificationService.getUnreadNotification()
				.subscribe(value => {
					this.setNotificationAmount(value?.count || 0)
				})
		}
	}

}
