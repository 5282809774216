import { Injectable } from '@angular/core'
import { ApiService } from '@core/services/api/api.service'
import { DataService } from '@core/interfaces/data-service'
import { TableLazyLoadEvent } from 'primeng/table'
import { Observable } from 'rxjs'
import { Notification } from '@core/models/shared/communication/notification.model'

@Injectable({ providedIn: 'root' })
export class NotificationService extends ApiService implements DataService {
  protected override endpoint: string = 'shared/communication/notifications'

  public getCollection(event: TableLazyLoadEvent): Observable<Notification[]> {
    return this._getCollection<Notification>(event)
  }

  public getItem(id: number): Observable<Notification> {
    return this._getItem<Notification>(id)
  }

  public getTotalItems(): number {
    return this._getTotalItems()
  }

  public getOverview(onlyUnread: boolean): Observable<Notification[]> {
    const sort: TableLazyLoadEvent =  { sortField: 'id', sortOrder: 2 }
    if(onlyUnread) {
      sort.filters = {}
      sort.filters['unread'] = { value: true }
    }
    return this.getCollection(sort)
  }

  public changeReadStatus(id: number, unread: boolean): Observable<Notification> {
    return this._patch(id, { unread: unread })
  }

  public getUnreadNotification(): Observable<{ count: number }> {
    return this._get('unread', false, true)
  }

}
